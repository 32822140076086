<template>
  <v-fab-transition>
    <v-btn :class="{'center-x': center}" :loading="loading" :right="!center" :rounded="center"
           :width="center ? '210px' : null"
           bottom color="primary"
           elevation="2" fab fixed @click="$emit('click')">
      <span v-if="text">{{ text }}</span>
      <v-icon v-else>{{ icon }}</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'KurccFAB',
  props: {
    center: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-plus'
    }
  }
}
</script>

<style scoped>
/deep/ .v-btn--absolute.v-btn--bottom, .v-btn--fixed.v-btn--bottom {
  bottom: 72px;
}
</style>
